import React from 'react'
import { Layout } from '../components/AppLayout'
import { RDHelmet } from '../components/RDHelmet'
import { AppContactUsBanner } from '../components/AppContactUsBanner'
// import { ContactUs } from '../components/ContactUs'
import { AppContactUsCard } from '../components/AppContactUsCard'
import { AppMapCantact } from '../components/AppMapCantact'

const contact = () => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '408-352-5162', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' 226 Airport Parkway',
      addressRegion: ' Suite 250 San Jose, California 95110',
      postalCode: '95110',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }

  return (
    <Layout>
      <RDHelmet
        subtitle={'Contact Us'}
        contentDescription="Get in touch with us for inquiries, support, or partnership opportunities. We're here to help!"
        contentKeywords="content='Contact, Support, Inquiries, Customer service, Partnership, Assistance, Get in touch, Contact form, Help center, Reach out'"
        contentOG="AI Powered Source-to-Pay SaaS Platform"
        contentOgDescription="Get in touch with us for inquiries, support, or partnership opportunities. We're here to help!"
        contentOgUrl="https://raindrop.com/contact"
        schemaMarkup={schemaMarkup}
      />
      <AppContactUsBanner />
      <AppContactUsCard />
      {/* <ContactUs /> */}
      <AppMapCantact />
    </Layout>
  )
}

export default contact
